import React, { useEffect, useState, useRef } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GET_METHOD } from "../../../api/api";
import Loader from "../../Loader/Loader";
import './SingleProductImage.css';
// import { useDispatch, useSelector } from 'react-redux';
// import { setWindowWidth } from '../../../stores/slice';

export default function SingleProductImage({ itemId, data, multiImages, setMultiImages }) {
    const [activeThumbnail, setActiveThumbnail] = useState(0);
    const sliderRef = useRef(null);
    const [loading, setLoading] = useState(true);
    // const windowWidth = useSelector((state) => state.store.Screenwidth);
    // const dispatch = useDispatch();

    // useEffect(() => {
    //     const handleResize = () => {
    //         dispatch(setWindowWidth(window.innerWidth));
    //     };
    //     window.addEventListener('resize', handleResize);
    //     return () => window.removeEventListener('resize', handleResize);
    // }, [dispatch]);

    useEffect(() => {
        const fetchData = async () => {
            const res = await GET_METHOD(`https://knitting.azurewebsites.net/api/ECommerceApi/GetItemMultiImagesAndVideos?ItemId=${itemId}`);
            if (res === null) {
                setMultiImages([]);
                setLoading(false);
            }
            else {
                setMultiImages(res);
                setLoading(false);
            }

        };
        fetchData();
    }, [itemId, setMultiImages]);


    const handleThumbnailClick = (index) => {
        setActiveThumbnail(index);
        sliderRef.current.slickGoTo(index);
    };

    const NextArrow = ({ onClick }) => (
        <div className="custom-arrow next-arrow" onClick={onClick}>
            &#10095;
        </div>
    );

    const PrevArrow = ({ onClick }) => (
        <div className="custom-arrow prev-arrow" onClick={onClick}>
            &#10094;
        </div>
    );

    const thumbnailSliderSettings = {
        speed: 300,
        slidesToShow: multiImages?.length < 5 ? multiImages?.length : 5,
        slidesToScroll: 1,
        arrows: false,
        focusOnSelect: true,
        vertical: true,
        verticalSwiping: true,
        responsive: [
            {
                breakpoint: 860,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll : 1,
                    arrows: false,
                    vertical: false,
                    verticalSwiping: false,
                },
            }
        ]
    };

    const sliderSettings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        ref: sliderRef,
        fade: true,
        nextArrow: <NextArrow onClick={() => sliderRef.current.slickNext()} />,
        prevArrow: <PrevArrow onClick={() => sliderRef.current.slickPrev()} />,
    };

    // console.log(multiImages, 'multiimages');

    return (
        <div className='single-product-img'>
            {loading && <Loader />}
            <div className='thumbnail-slider'>
                {multiImages?.length > 1 ?
                    <Slider {...thumbnailSliderSettings}>
                        {multiImages?.map((img, index) => (
                            <div
                                key={index}
                                className={`thumbnail ${activeThumbnail === index  ? 'active' : ''}`}
                                onClick={() => handleThumbnailClick(index)}
                            >
                                {img?.Type === 2 ?
                                    <video style={{ height: '60px', width: '60px' }}>
                                        <source src={img?.Media} type="video/mp4" className='thumbnail-image' />
                                    </video>
                                    :
                                    <img src={img?.Media} alt={`Thumbnail ${index }`} className='thumbnail-image' />
                                }
                            </div>
                        ))}
                    </Slider>
                    : ''}
            </div>
            <div className="img-slider">
                {multiImages?.length > 1 ? (
                    <Slider {...sliderSettings} initialSlide={activeThumbnail}>
                        {multiImages?.map((img, index) => (
                            img?.Type === 2 ? (

                                <div key={index} >
                                    <video controls className='video-img-slider' >
                                        <source src={img?.Media} type="video/mp4" />
                                    </video>
                                </div>

                            ) : (
                                <div key={index} >
                                    <img src={img?.Media} alt={`Product ${index}`} className='main-product-image' />
                                </div>
                            )
                        ))}
                    </Slider>
                ) : (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={data[0]?.ItemImage} alt='Default product' className='main-product-image' />
                    </div>
                )}
            </div>
        </div>
    );
}
