import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IoIosCheckmarkCircle } from "react-icons/io";
import "./OrderSummary.css";
import Button from "../../Component/Button/Button";
import Footer from "../../Component/Footer/Footer";
import Navbar from "../../Component/Navigation/Navbar/Navbar";
import { GET_METHOD } from "../../api/api";
import { useDispatch } from "react-redux";
import { cartRemoveAllProducts } from "../../stores/slice";
import OrderStatus from "../../Component/CheckoutComponents/OrderStatus/OrderStatus";
import { IoBanOutline } from "react-icons/io5";

export default function OrderSummary() {
  // const location = useLocation();
  // const checkoutData = location.state?.checkoutData || [];
  const navigate = useNavigate();
  const [productData, setProductData] = useState([]);
  const { id } = useParams();
  const cartId = id;
  // console.log(checkoutData, "checkout Data");
  const dispatch = useDispatch();
  dispatch(cartRemoveAllProducts());
  useEffect(() => {
    const fetchData = async () => {
      const res = await GET_METHOD(
        `https://knitting.azurewebsites.net/api/ECommerceApi/GetInvoiceDetail?CartId=${cartId}`
      );
      setProductData(res);
    };
    fetchData();
  }, [cartId]);

  var totalAmount = 0;
  var discount = 0;
  var shippingCharges = 0;

  // const statusSteps = {
  //     'On Progress' : 1,
  //     'Assigned' : 2,
  //     'Delivered' :3,
  // };

  const handleObject = (desc) => {
    // console.log(desc, 'description');
    if (typeof desc === 'object' && Object.keys(desc).length === 0) {
      return '';
    } else if (typeof desc === 'object') {
      return JSON.stringify(desc);
    } else {
      return desc;
    }
  };

  console.log('product data', productData);

  return (
    <>
      <Navbar />
      {productData?.length > 0 ?
        <div className="main-order-summary">
          <div className="order-summary-thankyou-main-div">
            <h1 className="order-summary-heading">
              Thank you for your purchase!
            </h1>
            <div className="order-summary-confirmed-div">
              <h2>Order confirmed Sucessfully</h2>
              <IoIosCheckmarkCircle size={50} color="green" />
              <p className="order-summary-order-id">
                {" "}
                <b>Order Number : </b> {handleObject(productData[0]?.OrderNo)}
              </p>
              <div>
                <OrderStatus currentStatus={handleObject(productData[0]?.CartStatus)} />
              </div>
            </div>

            <div className="order-summary-order-details">
              <h2>Order Details</h2>
              <div className="sub-order-summary-order-details">
                <div className="order-summary-order-details-div">
                  <h3>Shipping Address</h3>
                  <p>{handleObject(productData[0]?.CustomerName)}</p>
                  <p>{handleObject(productData[0]?.DeliveryAddress)}</p>
                  {/* <p>{productData[0]?.StateName}</p>
                                <p>{productData[0]?.CityName}</p> */}
                  <p>{handleObject(productData[0]?.CountryName)}</p>
                </div>

                <div>
                  <h3>Contact Information</h3>
                  <p>{handleObject(productData[0]?.CustomerEmail)}</p>
                  <p>{handleObject(productData[0]?.CustomerNo)}</p>
                </div>
              </div>
            </div>

            <div className="order-summary-btn">
              <Button
                text="Continue Shopping"
                onClick={() => navigate("/shop")}
                style={{ padding: '0rem', lineHeight: 0, height: '3rem', width: '250px' }}
              />
            </div>
          </div>

          <div className="order-summary-products-main-div">
            <div className="order-summary-sub-checkout-products">
              {productData.map((item, index) => {
                totalAmount = item.TotalAmount1;
                discount = discount + parseInt(item.TotalDiscount);
                shippingCharges = typeof (item.DeliveryCharges) === Number ?
                  shippingCharges + parseInt(item.DeliveryCharges) : 0;

                return (
                  <div className="single-product-info" key={index}>
                    <div className="checkout-products-information">
                      <div className="checkout-products-img-quantity">
                        <img src={item.ImageURL} alt="product-img" />
                        <span>{item.ItemQty}</span>
                      </div>
                      <div>
                        <p className="checkout-products-title">
                          {item.ItemName}
                        </p>
                        <p className="order-summary-item-description">
                          {item.ItemDescription}
                        </p>
                        <p style={{ fontSize: "0.8em" }}>
                          ${item.UnitPrice} x {item.ItemQty}
                        </p>
                        <p>${item.TotalAmount}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="order-summary-checkout-product-pricing">
              <div className="checkout-product-price-div">
                <p>Total Discount</p>
                <p>${discount}</p>
              </div>

              <div className="checkout-product-price-div">
                <p>Delivery Charges</p>
                <p>${shippingCharges}</p>
              </div>

              <div className="checkout-product-price-div">
                <p>Sub total</p>
                <p>${totalAmount}</p>
              </div>

              <hr />

              <div className="order-summary-checkout-product-price-div">
                <p style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                  {" "}
                  Total{" "}
                </p>
                <p style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                  ${totalAmount + shippingCharges + discount}
                </p>
              </div>
            </div>
          </div>
        </div> :
        <div className="main-order-summary-empty">
          <h1 className="order-summary-heading">No order found</h1>
          <Button text='Continue Shopping' onClick={() => navigate('/shop')} />
          <IoBanOutline size={50} className="order-not-found-img" />
        </div>
      }
      <Footer />
    </>
  );
}
