import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    cart: [],
    wishlist: [],
    category: [],
    campusId: 50,
    Screenwidth: window.innerWidth,
    deliveryInformation: [],
    checkoutInformation: [],
    errorDeliveryForm : [],
};

export const slice = createSlice({
    name: 'store',
    initialState,
    reducers: {
        cartAddProducts: (state, action) => {
            const newItem = action.payload;
            const existingItemIndex = state.cart.findIndex(item =>
                item.ItemId === newItem.ItemId &&
                item.color === newItem.color &&
                item.size === newItem.size
            );

            if (existingItemIndex !== -1) {
                state.cart[existingItemIndex].purchaseQuantity += newItem.purchaseQuantity;
            } else {
                state.cart.push(newItem);
            }
        },
        updateColorCart: (state, action) => {
            const { color, index } = action.payload;
            state.cart[index].color = color;
        },
        updateSizeCart: (state, action) => {
            const { size, index } = action.payload;
            state.cart[index].size = size;
        },
        cartRemoveProducts: (state, action) => {
            const { ItemId, index } = action.payload;
            if (state.cart[index] && state.cart[index].ItemId === ItemId) {
                state.cart.splice(index, 1);
            }
        },

        cartRemoveAllProducts: (state, action) => {
            state.cart = [];
        },

        wishlistAddProducts: (state, action) => {
            const exists = state.wishlist.some(product => product.ItemId === action.payload.ItemId);
            if (!exists) {
                state.wishlist.push(action.payload);
            }
        },
        wishlistRemoveProducts: (state, action) => {
            state.wishlist = state.wishlist.filter((product) => product.ItemId !== action.payload.ItemId);
        },
        updateProductQuantity: (state, action) => {
            const { index, quantity } = action.payload;
            state.cart[index].purchaseQuantity = quantity;
        },

        wishlistRemoveAllProducts: (state, action) => {
            state.wishlist = [];
        },

        setCategory: (state, action) => {
            state.category = action.payload;
        },
        setWindowWidth: (state, action) => {
            state.Screenwidth = action.payload;
        },

        setCheckOutInformation: (state, action) => {
            state.checkoutInformation = action.payload;
        },
        setDeliveryInformation: (state, action) => {
            state.deliveryInformation = action.payload;
        },
        setErrorDeliveryForm : (state, action) => {
            state.errorDeliveryForm = action.payload; 
        },
        resetState: () => initialState
    }
});

export const {
    cartAddProducts,
    updateSizeCart,
    updateColorCart,
    cartRemoveProducts,
    cartRemoveAllProducts,
    wishlistAddProducts,
    wishlistRemoveProducts,
    wishlistRemoveAllProducts,
    updateProductQuantity,
    setCategory,
    setWindowWidth,
    setCheckOutInformation,
    setDeliveryInformation,
    setErrorDeliveryForm,
    resetState
} = slice.actions;

export default slice.reducer;
