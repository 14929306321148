import React, { useState, useEffect } from 'react';
import "./CheckoutDelieveryForm.css";
import { GET_METHOD } from "../../../api/api";
import { useSelector } from 'react-redux';

export default function CheckoutDelieveryForm({ onChange }) {
    const [country, setCountry] = useState('');
    const [countryData, setCountryData] = useState([]);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [city, setCity] = useState('');
    const [email, setEmail] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [state, setState] = useState('');
    const [description, setDescription] = useState('');
    const [stateData, setStateData] = useState([]);
    const [cityData, setCityData] = useState([]);

    const errorInfo = useSelector((state) => state.store.errorDeliveryForm);

    useEffect(() => {
        const fetchData = async () => {
            const res = await GET_METHOD(`https://knitting.azurewebsites.net/api/ECommerceApi/GetCountry`);
            setCountryData(res);
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (country) {
            const fetchData = async () => {
                const res = await GET_METHOD(`https://knitting.azurewebsites.net/api/ECommerceApi/GetState?CountryId=${country}`);
                setStateData(res);
            };
            fetchData();
        }
    }, [country]);

    useEffect(() => {
        if (state) {
            const fetchData = async () => {
                const res = await GET_METHOD(`https://knitting.azurewebsites.net/api/ECommerceApi/GetCity?StateId=${state}`);
                setCityData(res);
            };
            fetchData();
        }
    }, [state]);

    const updateFormData = () => {
        const updatedData = {
            country,
            firstName,
            lastName,
            address,
            phone,
            city,
            postalCode,
            email,
            description,
        };
        onChange(updatedData);
    };

    // Update the form data after each change
    const handleCountryChange = (e) => {
        setCountry(e.target.value);
        updateFormData(); // Call this to update the form data after country is selected
    };

    const handleStateChange = (e) => {
        setState(e.target.value);
        updateFormData(); // Call this to update the form data after state is selected
    };

    const handleCityChange = (e) => {
        setCity(e.target.value);
        updateFormData(); // Call this to update the form data after city is selected
    };

    const handleInputChange = (setter) => (e) => {
        setter(e.target.value);
        updateFormData();
    };

    return (
        <div className='main-checkout-delievery-form'>
            <form onChange={updateFormData}>
                <h4 className='checkout-form-heading'>Delivery</h4>

                <input
                    type='text'
                    placeholder='Enter email'
                    className={errorInfo?.email === '' ? 'checkoutform-big-input-required' : 'checkoutform-big-input'}
                    value={email}
                    onChange={handleInputChange(setEmail)}
                    required
                />

                <div className='checkoutform-double-input'>
                    <input
                        type='text'
                        placeholder='First Name'
                        value={firstName}
                        onChange={handleInputChange(setFirstName)}
                        className={errorInfo?.firstName === '' ? 'checkout-normal-input-required' : 'checkout-normal-input'}
                        required
                    />

                    <input
                        type='text'
                        placeholder='Last Name'
                        value={lastName}
                        onChange={handleInputChange(setLastName)}
                        className={errorInfo?.lastName === '' ? 'checkout-normal-input-required' : 'checkout-normal-input'}
                        required
                    />
                </div>

                <select
                    className={errorInfo?.country === '' ? 'checkoutform-big-select-required' : 'checkoutform-big-select'}
                    onChange={handleCountryChange}
                    value={country}
                    required
                >
                    <option value="" disabled>Select Country/Region</option>
                    {countryData?.map((country) => (
                        <option key={country?.CountryId} value={country?.CountryId}>{country?.CountryName}</option>
                    ))}
                </select>

                <select
                    className='checkoutform-big-select'
                    value={state}
                    onChange={handleStateChange}
                >
                    <option value="" disabled>Select State</option>
                    {stateData?.map((state) => (
                        <option key={state?.StateId} value={state?.StateId}>{state?.StateName}</option>
                    ))}
                </select>

                <div className='checkoutform-double-input'>
                    <select
                        className='checkoutform-select'
                        value={city}
                        onChange={handleCityChange}
                    >
                        <option value="" disabled>Select City</option>
                        {cityData?.map((city) => (
                            <option key={city?.CityId} value={city?.CityId}>{city?.CityName}</option>
                        ))}
                    </select>

                    <input
                        type='text'
                        placeholder='Postal Code (optional)'
                        value={postalCode}
                        onChange={handleInputChange(setPostalCode)}
                        className={errorInfo?.postalCode === '' ? 'checkout-normal-input-required' : 'checkout-normal-input'}
                        required
                    />
                </div>

                <input
                    className={errorInfo?.address === '' ? 'checkoutform-big-input-required' : 'checkoutform-big-input'}
                    placeholder='Complete Address'
                    type='text'
                    value={address}
                    onChange={handleInputChange(setAddress)}
                    required
                />

                <input
                    placeholder='Phone'
                    className={errorInfo?.phone === '' ? 'checkoutform-big-input-required' : 'checkoutform-big-input'}
                    value={phone}
                    onChange={handleInputChange(setPhone)}
                    required
                />

                <textarea
                    placeholder='Description'
                    className='checkoutform-big-input'
                    style={{ height: '50px' }}
                    value={description}
                    onChange={handleInputChange(setDescription)}
                />
            </form>
        </div>
    );
}
