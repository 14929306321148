import React, { useState } from 'react';
import './ContactForm.css';
import { FaUpload } from 'react-icons/fa';
import { POST_METHOD } from '../../../api/api';
import PopupForm from '../../CartComponents/PopupForm/PopupForm';
import Loader from '../../Loader/Loader';

export default function ContactForm() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [country, setCountry] = useState('');
    const [product, setProduct] = useState('');
    const [quantity, setQuantity] = useState('');
    const [category, setCategory] = useState('');
    const [subject, setSubject] = useState('');
    const [address, setAddress] = useState('');
    const [popupSucess, setPopupSucess] = useState(false);
    const [formMessage, setFormMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false); // New state to track form submission

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    const truncateFileName = (name, length) => {
        if (name.length > length) {
            return name.slice(0, length) + '...';
        }
        return name;
    };

    const handleCustomForm = async (e) => {
        e.preventDefault();
        setFormSubmitted(true); // Set form submitted to true when the form is submitted

        if (name === '' || email === '' || address === '') {
            return;
        }

        const formData = new FormData();
        formData.append('FullName', name);
        formData.append('Email', email);
        formData.append('PhoneNo', phone);
        formData.append('Description', message);
        formData.append('Files', selectedFile);
        formData.append('Country', country);
        formData.append('Product', product);
        formData.append('Category', category);
        formData.append('Quantity', quantity);
        formData.append('Subject', subject);
        formData.append('DeliveryAddress', address);

        try {
            setLoading(true);
            const response = await POST_METHOD('https://knitting.azurewebsites.net/api/ECommerceApi/SendCustomLeatherEmail', formData);
            console.log(response);
            if (response.success) {
                setLoading(false);
                setAddress('');
                setCategory('');
                setCountry('');
                setEmail('');
                setName('');
                setPhone('');
                setProduct('');
                setQuantity('');
                setSelectedFile(null);
                setSubject('');


                setPopupSucess(true);
                setFormMessage(response.data);

                setTimeout(() => {
                    setPopupSucess(false)
                }, 5000)
                
            }
        } catch (error) {
            console.error('Error:', error.message);
            setAddress('');
            setCategory('');
            setCountry('');
            setEmail('');
            setName('');
            setPhone('');
            setProduct('');
            setQuantity('');
            setSelectedFile(null);
            setSubject('');
            setMessage('');
        }
    };

    return (
        <div className="main-contact-form">
            {loading && <Loader />}
            <div className="sub-div-contact-form">
                <div className="heading-form">
                    <h1>Request Your Custom Leather Jacket</h1>
                </div>

                <form className="contact-form" onSubmit={handleCustomForm}>
                    <div className="dual-input-field">
                        <div>
                            <label className="contact-form-label">Full Name</label> <br />
                            <input
                                type="text"
                                className={formSubmitted && !name ? "contact-form-input-required" : "contact-form-input"}
                                placeholder="Enter your name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div>
                            <label className="contact-form-label">Email Address</label> <br />
                            <input
                                type="email"
                                className={formSubmitted && !email ? "contact-form-input-required" : "contact-form-input"}
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="dual-input-field">
                        <div>
                            <label className="contact-form-label">Phone Number</label> <br />
                            <input
                                type="tel"
                                className="contact-form-input"
                                placeholder="Enter your phone number"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </div>

                        <div>
                            <label className="contact-form-label">Select Product</label> <br />
                            <select className="contact-form-select" onChange={(e) => setProduct(e.target.value)} value={product}>
                                <option defaultValue value=''>Choose your product</option>
                                <option value="jackets">Jackets</option>
                                <option value="patches">Patches</option>
                                <option value='bags'>Bags</option>
                            </select>
                        </div>
                    </div>

                    <div className="dual-input-field">
                        <div>
                            <label className="contact-form-label">Quantity</label> <br />
                            <select className="contact-form-select" onChange={(e) => setQuantity(e.target.value)} value={quantity}>
                                <option defaultValue value=''>Select quantity</option>
                                <option value="1">1</option>
                                <option value="1-5">1 - 5</option>
                                <option value="6-20">6 - 20</option>
                                <option value="21-50">21 - 50</option>
                                <option value="50+">50+</option>
                            </select>
                        </div>

                        <div>
                            <label className="contact-form-label">Country</label> <br />
                            <select className="contact-form-select" onChange={(e) => setCountry(e.target.value)} value={country}>
                                <option defaultValue value=''>Select your country</option>
                                <option value="USA">USA</option>
                                <option value="Canada">Canada</option>
                                <option value="England">England</option>
                                <option value="Germany">Germany</option>
                            </select>
                        </div>
                    </div>

                    <div className="dual-input-field">
                        <div>
                            <label className="contact-form-label">Category</label> <br />
                            <select className="contact-form-select" onChange={(e) => setCategory(e.target.value)} value={category}>
                                <option defaultValue value=''>Select your product category</option>
                                <option value="Men">Men</option>
                                <option value="Women">Women</option>
                                <option value="Kids">Kids</option>
                            </select>
                        </div>

                        <div>
                            <label className="contact-form-label">Select Art-work or design</label> <br />
                            <label htmlFor="file-upload" className="file-upload-button">
                                <FaUpload className="upload-icon" />
                                Choose File
                            </label>
                            <input type="file" id="file-upload" onChange={handleFileChange} />
                            {selectedFile && (
                                <div className="file-info">
                                    <p style={{ width: '100%', textWrap: 'wrap', display: 'flex', justifyContent: 'center' }}>
                                        Selected File: {truncateFileName(selectedFile.name, 15)}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="dual-input-field">
                        <div>
                            <label className="contact-form-label">Enter Subject</label> <br />
                            <input
                                type="text"
                                className="contact-form-input"
                                placeholder="Enter subject"
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                            />
                        </div>
                        <div>
                            <label className="contact-form-label">Delivery Address</label> <br />
                            <input
                                type="text"
                                className={formSubmitted && !address ? "contact-form-input-required" : "contact-form-input"}
                                placeholder="Enter your delivery address"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                            />
                        </div>
                    </div>

                    <label className="contact-form-label">Description</label>
                    <textarea
                        placeholder="Please provide details about your custom order"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        className="contact-form-text-area"
                    />

                    <button className="contact-form-button" type="submit">
                        Submit Request
                    </button>
                </form>
            </div>
            {popupSucess &&
                <PopupForm
                    showPopup={popupSucess}
                    message={formMessage}
                    onClose={() => setPopupSucess(false)}
                    title='Request Form'
                    sucess={true}
                />
            }
        </div>
    );
}
