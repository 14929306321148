import React, { useState } from 'react'
import Button from '../../Button/Button'
import "./styles.css"
import { useNavigate } from 'react-router-dom';

export default function SearchOrder() {

    const [order , setOrder] = useState('');
    const navigate = useNavigate();

    const handleOrderNumber = () =>{
        navigate(`/ordersummary/${order}`);
    }

    return (
        <form className='search-order-main' onSubmit={handleOrderNumber}>
            <input
                className='search-order-input'
                placeholder='Enter order number'
                onChange={(e)=>setOrder(e.target.value)}
            />
            <Button text='Search' style={{ lineHeight: 0, padding: 0, height: '30px', width: '100px' }} type='Submit' />
        </form>
    )
}
