import React from "react";
import "./OrderStatus.css";

const OrderStatus = ({ currentStatus }) => {
    // console.log(currentStatus, 'currentStatus');
    const steps = [
        { id: 49, status: "On Progress" },
        { id: 50, status: "Assigned" },
        { id: 51, status: "Delivered" },
        { id: 52, status: "Cancelled" }
    ];

    const currentStepIndex = steps.findIndex(step => step.id === currentStatus);

    const getStepClassName = (index) => {
        if (index < currentStepIndex) {
            return "step completed";
        }
        if (index === currentStepIndex) {
            return "step active";
        }
        if(currentStatus === 52){
            return 'step cancel'
        }
        return "step";
    };

    return (
        <div className="order-status">
            <div className="progress-bar">
                {steps.map((step, index) => {
                    if (index < 3) {
                        return (
                            <div key={step.id} className={getStepClassName(index , currentStatus)}>
                                <div className="circle">
                                    {index <= currentStepIndex ? (
                                        <span>&#10003;</span>
                                    ) : (
                                        index + 1
                                    )}
                                </div>
                                <span className="label">
                                    {index === 2 ? "Delivered / Cancelled" : step.status}
                                </span>
                            </div>
                        );
                    }
                    return null;
                })}
            </div>
        </div>
    );
};

export default OrderStatus;
