import React from 'react';
import "./SizePage.css"
import menSize from "../../assets/SizeChart.png"
import womenSize from "../../assets/womens-sizechart.png"
// import childSize from "../../assets/CHILD COMPELETE.png"
import Navbar from '../../Component/Navigation/Navbar/Navbar';
import Footer from '../../Component/Footer/Footer';

export default function SizePage(){
    return (
        <div className='main-sizepage'>
            <Navbar /> 
            <h1 className='size-heading'>Size Chart</h1>
            <div className='sizeChart-div'>
                <img src={menSize}  alt='men-size-chart'/>
            </div>

            <div className='sizeChart-div'>
                <img src={womenSize}  alt='women-size-chart'/>
            </div>

            {/* <div className='sizeChart-div'>
                <img src={childSize}  alt='kids-size-chart'/>
            </div> */}
            <Footer /> 
        </div>
    );
}

