import React from 'react';
import { useEffect, useState } from 'react';
import './Navbar.css';
import { CiShoppingCart, CiHeart, CiSearch } from "react-icons/ci";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import logo from "../../../assets/logo.png"
import { HiMenuAlt3 } from "react-icons/hi";
import { useNavigate } from 'react-router-dom';
import { GET_METHOD } from '../../../api/api';
import { useDispatch, useSelector } from 'react-redux';
import { setCategory } from '../../../stores/slice';
import Cart from "../../CartComponents/Cart/Cart";
import WishlistSlider from '../../WishlistComponents/WishlistSlider/WishlistSlider';

export default function Navbar() {
    const isOpen = false;
    const [showSearch, setShowSearch] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [navbarColor, setNavbarColor] = useState(false);
    const [categoriesOpen, setCategoriesOpen] = useState(false);
    const [search, setSearch] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cart = useSelector((state) => state.store.cart);
    const categoryItems = useSelector((state) => state.store.category);

    // const windowWidth = useSelector((state) => state.store.Screenwidth);

    // useEffect(() => {
    //     if (windowWidth <= 1050) {
    //         setDrawerOpen(false);
    //     }
    // }, [windowWidth])

    const [isCartOpen, setCartOpen] = useState(false);
    const [isWishOpen, setWishOpen] = useState(false);

    const openCart = () => setCartOpen(true);
    const closeCart = () => setCartOpen(false);

    const openWishList = () => setWishOpen(true);
    const closeWishList = () => setWishOpen(false);


    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 40) {
                setNavbarColor(true);
            } else {
                setNavbarColor(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleSearchClick = () => {
        setShowSearch(true);
    };

    const handleCloseSearch = () => {
        setShowSearch(false);
    };

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const toggleCategories = () => {
        setCategoriesOpen(!categoriesOpen);
    };

    useEffect(() => {
        if (showSearch) {
            document.body.style.overflow = 'hidden';
            document.body.style.backgroundColor = 'rgba(0,0,0,0.5)';
        } else {
            document.body.style.overflow = 'auto';
            document.body.style.backgroundColor = '';
        }
    }, [showSearch, drawerOpen]);

    useEffect(() => {
        let shouldFetchData = true;
        const fetchData = async () => {
            const res = await GET_METHOD(`https://knitting.azurewebsites.net/api/ECommerceApi/GetAllCategoryVenueWise?CampusId=50`);
            // console.log('navbar category res', res);
            if (res === null) {
                dispatch(setCategory([]))
            }
            else {
                dispatch(setCategory(res));
            }
        }

        if (shouldFetchData) {
            fetchData();
        }
        return () => {
            shouldFetchData = false;
        };
    }, [dispatch])

    // console.log(categoryItems, 'categoryItems');

    const handleSearch = (e) => {
        navigate(`/search/${search}`);
        setShowSearch(false);
    }

    // const drawerRef = useRef(null);

    // const handleOverlayClick = (e) => {
    //     if (drawerRef.current && !drawerRef.current.contains(e.target)) {
    //         setDrawerOpen(false);
    //     }
    // };

    // useEffect(() => {
    //     document.addEventListener('mousedown', handleOverlayClick);
    //     return () => {
    //         document.removeEventListener('mousedown', handleOverlayClick);
    //     };
    // }, []);

    const replaceSpacesWithHyphens = (str) => {
        return str.replace(/\s+/g, '-').toLowerCase();
    }

    const handleCategory = (id, CategoryName) => {
        navigate(`/category/${replaceSpacesWithHyphens(CategoryName)}/${id}`);
    }

    return (
        <div >
            {showSearch && (
                <form className="search-bar" onSubmit={handleSearch}>
                    <input
                        type="text"
                        placeholder="Search..."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        style={{ color: `var(--primary-text-color)` }}
                    />
                    <button className="search-close" onClick={handleCloseSearch}>×</button>

                    <CiSearch className='search-logo' type='submit' />
                </form>
            )}
            {!showSearch && (
                <div className={navbarColor ? 'navbar-color' : "navbar"}>
                    <div className="navbar-container">
                        <div className="navbar-left">
                            <ul className={`menu-items ${isOpen ? 'open' : ''}`}>
                                <li><p onClick={() => navigate("/")} style={{ marginTop: '4.15px' }} >Home</p></li>
                                <li >
                                    <p onClick={() => navigate("/shop")} style={{ marginTop: '4.15px' }} >
                                        Shop
                                    </p>
                                </li>
                                <li className="dropdown-parent">
                                    <p>
                                        Categories
                                        <MdOutlineKeyboardArrowDown className="arrow" />
                                    </p>
                                    <ul className="dropdown scrollable-dropdown">
                                        <div >
                                            {categoryItems?.map((item, index) => (
                                                <div key={index}>
                                                    <li key={item.Id} onClick={() => handleCategory(item.Id, item.CategoryName)}>
                                                        <p>{item.CategoryName}</p>
                                                    </li>

                                                </div>
                                            ))}
                                        </div>
                                    </ul>
                                </li>
                                <li >
                                    <p onClick={() => navigate("/about")} style={{ marginTop: '4.15px' }} >About Us</p>
                                </li>
                                <li>
                                    <p onClick={() => navigate("/contact")} style={{ marginTop: '4.15px' }} >Contact Us</p>
                                </li>

                                {/* <li>
                                    <p onClick={() => navigate("/blogs")} style={{ marginTop: '4.15px' }} >Blogs</p>
                                </li> */}

                                <li>
                                    <p onClick={() => navigate("/sizechart")} style={{ marginTop: '4.15px' }} >Size Chart</p>
                                </li>

                            </ul>
                        </div>
                        <div className="logo-container">
                            <img src={logo} onClick={() => navigate("/")} alt='logo' />
                        </div>
                        <div className="navbar-right">
                            <div className="logo-container-responsiveness">
                                <img src={logo} onClick={() => navigate("/")} alt='logo' />
                            </div>
                            <CiHeart size={25} onClick={openWishList} className='wishlist-logo' />
                            <div className='cart-quantity'>
                                <CiShoppingCart size={25} onClick={openCart} className='wishlist-cart' />
                                {cart?.length > 0 ? <span className='cart-number'>{cart?.length}</span> : null}
                            </div>
                            <CiSearch size={25} onClick={handleSearchClick} className='wishlist-search' />
                            <HiMenuAlt3 size={30} className="menu-toggle" onClick={toggleDrawer} />
                        </div>
                        <Cart isOpen={isCartOpen} onClose={closeCart} />
                        <WishlistSlider isOpen={isWishOpen} onClose={closeWishList} />
                    </div>
                </div>
            )}
            {drawerOpen && (
                <div className={`drawer ${drawerOpen ? 'open' : ''}`} >
                    <button className="drawer-close" onClick={toggleDrawer}>×</button>
                    <ul className="drawer-menu-items">
                        <li><p onClick={() => navigate("/")}>Home</p></li>
                        <li><p onClick={() => navigate('/shop')}>Shop</p></li>
                        <li className="drawer-dropdown">
                            <p onClick={toggleCategories}>
                                Categories
                                <MdOutlineKeyboardArrowDown className="arrow" />
                            </p>
                            {categoriesOpen && (
                                <ul className="drawer-dropdown-items open" >
                                    {categoryItems?.map((item) => (
                                        <li key={item.Id} onClick={() => handleCategory(item.Id, item.CategoryName)}>
                                            <p>{item.CategoryName}</p>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                        <li>
                            <p onClick={() => navigate("/about")}>About Us</p>
                        </li>
                        <li>
                            <p onClick={() => navigate("/contact")}>Contact Us</p>
                        </li>

                        {/* <li>
                            <p onClick={() => navigate("/blogs")} >Blogs</p>
                        </li> */}

                        <li>
                            <p onClick={() => navigate("/sizechart")}  >Size Chart</p>
                        </li>

                    </ul>
                </div>
            )}
        </div>
    );
}
