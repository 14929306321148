import React, { useState } from 'react';
import "./ContactUsForm.css"
import { FaUpload } from 'react-icons/fa';
import { POST_METHOD } from '../../../api/api';
// import Popup from '../../CartComponents/Popup/Popup';
import ReCAPTCHA from 'react-google-recaptcha';
import PopupForm from '../../CartComponents/PopupForm/PopupForm';

export default function ContactUsForm() {
    const [fname, setFName] = useState('');
    const [lname, setLName] = useState('');
    const [email, setEmail] = useState('');
    const [orderId, setOrderId] = useState("");
    const [message, setMessage] = useState('');
    const [topic, setTopic] = useState('');
    const [subject, setSubject] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [popup, setPopup] = useState(false);
    const [isHuman, setIsHuman] = useState(false);

    const [popupSucess, setPopupSucess] = useState(false);
    const [formMessage, setFormMessage] = useState("");

    const onReCAPTCHAChange = (value) => {
        console.log("Captcha value:", value);
        setIsHuman(!!value);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    const truncateFileName = (name, length) => {
        if (name.length > length) {
            return name.slice(0, length) + '...';
        }
        return name;
    };

    const handleContactUS = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('Files', selectedFile);
        formData.append('FirstName', fname);
        formData.append('LastName', lname);
        formData.append('Email', email);
        formData.append('Topic', topic);
        formData.append('Subject', subject);
        formData.append('Comment', message);
        formData.append('OrderId', orderId);
        formData.append('Key', '6LcZLz0qAAAAABge0cO9K-IalwVoc-TtObgUpOMC');

        try {
            if (isHuman) {

                if (fname === '' || lname === '' || email === '' || topic === '') {
                    setPopup(true);
                    setTimeout(() => setPopup(false), 5000);
                    return
                }

                else {
                    const response = await POST_METHOD('https://knitting.azurewebsites.net/api/ECommerceApi/SendContactUsForLeatherCraft', formData);
                    console.log(response);
                    setPopupSucess(true);
                    setEmail('');
                    setFName('');
                    setLName('');
                    setMessage('');
                    setTopic('');
                    setOrderId('');
                    setSelectedFile('');
                    setSubject('');

                    setTimeout(()=>{
                        setPopupSucess(false);
                    },5000) 

                    setFormMessage(response.data);
                }

            }
            else {
                alert("Please verify that you are a human!");
            }

        } catch (error) {
            console.error('Error:', error.message);
            setEmail('');
            setFName('');
            setLName('');
            setMessage('');
            setTopic('');
            setOrderId('');
            setSelectedFile('');
            setSubject('');
        }
    }

    return (
        <div className="main-contactus-form">
            <div className="sub-div-contact-form">
                <div className="heading-form">
                    <h1>Contact Us</h1>
                    <p>If you need a little help, please fill out the form below. We aim to respond within 24-48 hrs, Monday to Friday</p>
                </div>

                <form className="contact-form" onSubmit={handleContactUS}>
                    <div className="dual-input-field">
                        <div>
                            <label className="contact-form-label">First Name</label> <br />
                            <input
                                type="text"
                                className="contact-form-input"
                                placeholder="Enter first name"
                                value={fname}
                                onChange={(e) => setFName(e.target.value)}
                            />
                        </div>
                        <div>
                            <label className="contact-form-label">Last Name</label> <br />
                            <input
                                type="text"
                                className="contact-form-input"
                                placeholder="Enter last name"
                                value={lname}
                                onChange={(e) => setLName(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="dual-input-field">
                        <div>
                            <label className="contact-form-label">Email Address</label> <br />
                            <input
                                type="email"
                                className="contact-form-input"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div>
                            <label className="contact-form-label">Select Topic</label> <br />
                            <select className="contact-form-select" onChange={(e) => setTopic(e.target.value)} value={topic} >
                                <option defaultValue>Choose your topic</option>
                                <option value="order query">Order Query</option>
                                <option value="return">Return </option>
                                <option value="complaint">Complaint</option>
                                <option value="general inquiry">General Inquiry</option>
                                <option value="Size & fit">Size & fit</option>
                                <option value="Feedback" >Feedback</option>
                            </select>
                        </div>
                    </div>

                    <div className="dual-input-field">

                        <div>
                            <label className="contact-form-label" >Add pictures</label> <br />
                            <label htmlFor="file-upload" className="file-upload-button">
                                <FaUpload className="upload-icon" />
                                Choose File
                            </label>
                            <input type="file" id="file-upload" onChange={handleFileChange} />
                            {selectedFile && (
                                <div className="file-info">
                                    <p style={{ width: '100%', textWrap: 'wrap', display: 'flex', justifyContent: 'center' }} >Selected File: {truncateFileName(selectedFile.name, 15)}</p>
                                </div>
                            )}
                        </div>

                        <div>
                            <label className="contact-form-label">Enter OrderId</label> <br />
                            <input
                                className='contact-form-input'
                                value={orderId}
                                type='number'
                                placeholder='Order Id'
                                onChange={(e) => setOrderId(e.target.value)} />
                        </div>
                    </div>


                    <label className="contact-form-label">Select Subject</label>
                    <select className="contact-form-select-big" onChange={(e) => setSubject(e.target.value)} value={subject} >
                        <option defaultValue>Select Subject</option>
                        <option value="Return Status">Return Status</option>
                        <option value="Return Policies">Return Policies</option>
                        <option value="Return Instructions">Return Instructions</option>
                        <option value="other">Other</option>
                    </select>

                    <label className="contact-form-label">Comments</label>
                    <textarea
                        placeholder=""
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        className="contact-form-text-area"
                    />

                    <ReCAPTCHA
                        sitekey="6LcZLz0qAAAAABge0cO9K-IalwVoc-TtObgUpOMC"
                        onChange={onReCAPTCHAChange}
                    /> <br />

                    <button className="contact-form-button">Submit</button>
                </form>
            </div>

            {popup &&
                <PopupForm
                    showPopup={popup}
                    message="Name, Email and topic are required for this form"
                    onClose={() => setPopup(false)}
                    title='Request Form'
                    form={true}
                />
            }

            {popupSucess &&
                <PopupForm
                    showPopup={popupSucess}
                    message={formMessage}
                    onClose={() => setPopupSucess(false)}
                    title='Request Form'
                    sucess={true}
                />
            }
        </div>
    );
}
