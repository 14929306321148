import React, { useState } from 'react';
import './CheckoutForm.css'
import CheckoutDelieveryForm from '../CheckoutDelieveryForm/CheckoutDelieveryForm';
import { setDeliveryInformation } from '../../../stores/slice';
import { useDispatch } from 'react-redux';

export default function CheckoutForm() {
    const [selectedPayment, setSelectedPayment] = useState('payfast');
    const [formData, setFormData] = useState({});
    const [deliveryFormData, setDeliveryFormData] = useState({});

    const dispatch = useDispatch();

    const handlePayment = (event) => {
        setSelectedPayment(event.target.value);
    };

    const handleForm = () => {
        setFormData({
            deliveryMethod: selectedPayment,
        })

        setFormData((prevFormData) => ({
            ...prevFormData,
            ...deliveryFormData,
        }));

        dispatch(setDeliveryInformation(formData));
    }


    return (
        <div className='main-checkoutform'>
            <form className='checkout-form' onChange={handleForm}>
               
                <h4 className='checkout-form-heading'>Payment Method</h4>
                <p>All transactions are secured and encrypted</p>
                <div className='checkout-method-div'>
                    <label className={selectedPayment === "payfast" ? 'checkout-method-div-label-selected' : 'checkout-method-div-label'}>
                        <input
                            type="radio"
                            name="paymentMethod"
                            value="payfast"
                            checked={selectedPayment === "payfast"}
                            onChange={handlePayment}
                        /> &nbsp;
                        PAYFAST (Pay via Debit/Credit/Wallet/Bank Account)
                    </label>
                </div>

                <CheckoutDelieveryForm onChange={setDeliveryFormData} />
            </form>
        </div>
    );
}

